import { Box, Flex, Input, Text } from "@chakra-ui/react";
import axios from "axios";
import GCPImage from "components/GCPImage";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import appStore from "store";

const ZHUYIN_TABLE = [
  "ㄅ",
  "ㄆ",
  "ㄇ",
  "ㄈ",
  "ㄉ",
  "ㄊ",
  "ㄋ",
  "ㄌ",
  "ㄍ",
  "ㄎ",
  "ㄏ",
  "ㄐ",
  "ㄑ",
  "ㄒ",
  "ㄓ",
  "ㄔ",
  "ㄕ",
  "ㄖ",
  "ㄗ",
  "ㄘ",
  "ㄙ",
  "ㄧ",
  "ㄨ",
  "ㄩ",
  "ㄚ",
  "ㄛ",
  "ㄜ",
  "ㄝ",
  "ㄞ",
  "ㄟ",
  "ㄠ",
  "ㄡ",
  "ㄢ",
  "ㄣ",
  "ㄤ",
  "ㄥ",
  "ㄦ",
  "ˇ",
  "ˋ",
  "ˊ",
  "˙",
];

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const HomeHeroAddressInput = ({
  searchText,
  setSearchText,
  placeholder = "輸入車站、飯店或你家地址",
  disabled,
  onClickGoogleMapResult,
  onClickMappingResult,
  setAddressInputZoneId,
  ...props
}) => {
  const { t } = useTranslation("common");
  const { deliveryZones, configs } = appStore;

  const [googleResult, setGoogleResult] = useState([]);
  const [mappingResult, setMappingResult] = useState([]);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  const debouncedSearchText = useDebounce(searchText, 100);

  const deliveryZonesFilteredNoSupport = deliveryZones.filter((zone) => {
    const isNotSupport = configs.filter((config) => {
      const isSupportZones = config.support_zones.filter((_zone) => {
        return _zone.id === zone.id;
      });
      // const isSupportZones = config.support_zones.ids.includes(
      //   zone.id.toString()
      // );

      return isSupportZones.length;
    });

    return isNotSupport.length && zone.level_3 == null;
  });

  useEffect(() => {
    if (debouncedSearchText.length > 1) {
      setMappingResult([]);
      setGoogleResult([]);
      const isVaild = !ZHUYIN_TABLE.some((i) => searchText.indexOf(i) != -1);
      if (isVaild) {
        setIsFetching(true);

        axios
          .get(`/api/google_map_api_search?input=${debouncedSearchText}`)
          .then((res) => {
            // console.log("google_map_api_search", res);
            if (res.data.data.predictions) {
              let mappingDeliveryZones = [];
              let googleResultZones = [];
              res.data.data.predictions.forEach((item) => {
                item?.terms.forEach((term, index) => {
                  if (index !== 0) {
                    const filteredZones = deliveryZonesFilteredNoSupport.filter(
                      (zone) =>
                        (term.value.includes(zone.level_1) ||
                          zone.level_1.includes(term.value)) &&
                        (item.terms[index - 1].value.toString ==
                          zone.level_2.toString() ||
                          zone.level_2.includes(item.terms[index - 1].value))
                    );

                    if (filteredZones.length > 1) {
                      // 檢查是否有重複的，有的話就不加入
                      filteredZones.forEach((filteredZone) => {
                        const isExist = mappingDeliveryZones.find(
                          (zone) => zone.id === filteredZone.id
                        );
                        if (!isExist) {
                          mappingDeliveryZones.push(filteredZone);
                        }
                      });
                    } else if (filteredZones.length === 1) {
                      if (filteredZones[0].level_3) {
                        filteredZones.forEach((filteredZone) => {
                          const isExist = mappingDeliveryZones.find(
                            (zone) => zone.id === filteredZone.id
                          );
                          if (!isExist) {
                            mappingDeliveryZones.push(filteredZone);
                          }
                        });
                      } else {
                        let _city = "";
                        let _zone = "";
                        const zone = filteredZones[0];

                        const isMatch = item.terms.find((term, index) => {
                          if (index !== 0) {
                            if (
                              (term.value.includes(zone.level_1) ||
                                zone.level_1.includes(term.value)) &&
                              (item.terms[index - 1].value.includes(
                                zone.level_2
                              ) ||
                                zone.level_2.includes(
                                  item.terms[index - 1].value
                                ))
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          }
                        });
                        const matchIndex = item.terms.indexOf(isMatch);
                        if (matchIndex !== -1) {
                          _city = item.terms[matchIndex].value;
                          _zone = item.terms[matchIndex - 1].value;
                        }

                        const address = item.description.split(_city + _zone);

                        googleResultZones.push({
                          ...item,
                          price: filteredZones[0].price,
                          level_1: _city,
                          level_2: _zone,
                          address: address[address.length - 1],
                        });
                      }
                    } else {
                    }
                  } else {
                  }
                });
              });

              setMappingResult(mappingDeliveryZones);
              setGoogleResult(googleResultZones);

              setIsFetching(false);
            }
          });
      }
    } else {
      setMappingResult([]);
      setGoogleResult([]);
    }
  }, [deliveryZones, debouncedSearchText]);

  return (
    <Flex
      flex="1"
      h="56px"
      borderRadius="8px"
      border="1px solid rgba(0, 0, 0, 0.40)"
      flexDir={{ base: "column", md: "row" }}
      align={{ base: "stretch", md: "center" }}
      pos="relative"
      {...props}
    >
      <Input
        border="0px"
        _focus={{ border: "0px" }}
        _hover={{ border: "0px" }}
        fontSize="16px"
        lineHeight="24px"
        placeholder={placeholder}
        ml="0px"
        mr="16px"
        h="56px"
        minH="56px"
        flex="1"
        align="center"
        py="8px"
        value={searchText}
        onChange={(e) => {
          setAddressInputZoneId("");
          setSearchText(e.target.value);
        }}
        onFocus={() => {
          setIsOpenMenu(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            setIsOpenMenu(false);
          }, 500);
        }}
      />
      {isOpenMenu && (
        <Box
          zIndex="999"
          pos="absolute"
          top={{ base: "40px", md: "60px" }}
          maxH={{ base: "300px", md: "100vh" }}
          overflowY="scroll"
          left="0"
          right="0"
          bg="white"
          mx="-4px"
          boxShadow={
            searchText.length > 1 ? "0px 4px 8px rgba(0, 0, 0, 0.4)" : ""
          }
          borderRadius="4px"
          mt={{ base: "16px", md: "0px" }}
        >
          {!!mappingResult.length &&
            mappingResult.map((item) => {
              return (
                <Flex
                  px="16px"
                  minH="62px"
                  _hover={{
                    bg: "gray.100",
                    cursor: "pointer",
                  }}
                  display="flex"
                  justify="start"
                  textAlign="start"
                  align="center"
                  key={item.id}
                  onClick={() => {
                    onClickMappingResult(item);
                    setSearchText(
                      item.level_1 +
                        item.level_2 +
                        (item.level_3 && item.level_3 !== null
                          ? item.level_3
                          : "")
                    );

                    setIsOpenMenu(false);
                  }}
                >
                  <GCPImage
                    mr="16px"
                    src="/images/groupB/location.png"
                    w="24px"
                    h="24px"
                  />
                  {/* <Box mr="16px">
                  <MdElectricCar size="24px" />
                </Box> */}
                  <Flex
                    py="8px"
                    justify="space-between"
                    w="100%"
                    align="center"
                    borderBottom="1px solid rgba(0, 0, 0, 0.10)"
                    flex="1"
                  >
                    <Box>
                      <Text fontSize="16px" lineHeight="24px" color="#333333">
                        {item.level_3 && item.level_3 !== null
                          ? t(`station_i18n.${item.level_3}`, {
                              defaultValue: item.level_3,
                            })
                          : t(`city_i18n.${item.level_1}`, {
                              defaultValue: item.level_1,
                            }) +
                            t(`zone_i18n.${item.level_2}`, {
                              defaultValue: item.level_2,
                            })}
                      </Text>
                      {/* {item.level_3 && item.level_3 !== null && (
                        <Box>
                          <Text
                            fontSize="14px"
                            lineHeight="20px"
                            as="span"
                            color="#748598"
                          >
                            {t(`city_i18n.${item.level_1}`, {
                              defaultValue: item.level_1,
                            })}
                          </Text>
                          <Text
                            fontSize="14px"
                            lineHeight="20px"
                            as="span"
                            color="#748598"
                          >
                            {t(`zone_i18n.${item.level_2}`, {
                              defaultValue: item.level_2,
                            })}
                          </Text>
                        </Box>
                      )} */}
                    </Box>
                    {!!item.price && (
                      <Text fontSize="16px" lineHeight="24px" color="#333333">
                        +{item.price}
                        {t("TWD")}
                      </Text>
                    )}
                  </Flex>
                </Flex>
              );
            })}
          {!!googleResult.length &&
            googleResult.map((item) => {
              return (
                <Flex
                  key={item.place_id}
                  w="100%"
                  minH="62px"
                  px="16px"
                  _hover={{
                    bg: "gray.100",
                    cursor: "pointer",
                  }}
                  display="flex"
                  justify="space-between"
                  textAlign="start"
                  align="center"
                  onClick={() => {
                    onClickGoogleMapResult(item);
                    setSearchText(item.description);
                    setIsOpenMenu(false);
                  }}
                >
                  <Flex w="100%" align="center">
                    <GCPImage
                      mr="16px"
                      src="/images/groupB/locationPin.png"
                      w="24px"
                      h="24px"
                    />
                    <Flex
                      align="center"
                      justify="space-between"
                      w="full"
                      flex="1"
                      py="8px"
                      borderBottom="1px solid rgba(0, 0, 0, 0.10)"
                    >
                      <Box>
                        <Text fontSize="16px" lineHeight="24px" color="#333333">
                          {item.structured_formatting.main_text}
                        </Text>

                        <Box>
                          <Text
                            fontSize="14px"
                            lineHeight="20px"
                            as="span"
                            color="#748598"
                          >
                            {t(`city_i18n.${item.level_1}`, {
                              defaultValue: item.level_1,
                            })}
                          </Text>
                          <Text
                            fontSize="14px"
                            lineHeight="20px"
                            as="span"
                            color="#748598"
                          >
                            {t(`zone_i18n.${item.level_2}`, {
                              defaultValue: item.level_2,
                            })}
                          </Text>
                        </Box>
                      </Box>
                      {!!item.price && (
                        <Text
                          ml="16px"
                          fontSize="16px"
                          lineHeight="24px"
                          color="#333333"
                          whiteSpace="nowrap"
                        >
                          +{item.price}
                          {t("TWD")}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              );
            })}

          {!isFetching &&
            searchText.length > 1 &&
            !mappingResult.length &&
            !googleResult.length && (
              <Flex
                pl="16px"
                minH="36px"
                display="flex"
                justify="start"
                textAlign="start"
                align="center"
              >
                <Flex
                  py="8px"
                  justify="space-between"
                  w="100%"
                  align="center"
                  borderBottom="1px solid rgba(0, 0, 0, 0.10)"
                  flex="1"
                >
                  <Text fontSize="14px" lineHeight="20px" color="#748598">
                    {t("Home_test_no_result")}
                  </Text>
                </Flex>
              </Flex>
            )}

          {!isFetching &&
            searchText.length > 1 &&
            mappingResult.length === 1 && (
              <Flex
                pl="16px"
                minH="36px"
                display="flex"
                justify="start"
                textAlign="start"
                align="center"
              >
                <Flex
                  py="8px"
                  justify="space-between"
                  w="100%"
                  align="center"
                  borderBottom="1px solid rgba(0, 0, 0, 0.10)"
                  flex="1"
                >
                  <Box>
                    <Text fontSize="14px" lineHeight="20px" color="#748598">
                      {t("Home_test_one_result")}
                      {!!mappingResult.length &&
                        (mappingResult[0].level_3 &&
                        mappingResult[0].level_3 !== null
                          ? mappingResult[0].level_3
                          : mappingResult[0].level_1 +
                            mappingResult[0].level_2)}

                      {!!googleResult.length &&
                        (googleResult[0].address &&
                        googleResult[0].address !== null
                          ? googleResult[0].address
                          : googleResult[0].level_1 + googleResult[0].level_2)}
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            )}
        </Box>
      )}
    </Flex>
  );
};
export default HomeHeroAddressInput;
