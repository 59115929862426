import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { addDays, differenceInDays, format, isAfter, isBefore } from "date-fns";
import { getDatePickerSettingsByConfigs } from "lib/order";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import appStore from "store";
import * as ga from "../../../lib/analytics";
import BackgroundImageBox from "./BackgroundImageBox";
import HomeHeroAddressInput from "./HomeHeroAddressInput";
import GCPImage from "components/GCPImage";
import HomeHeroTimeSelectBox from "./HomeHeroTimeSelectBox";
import { MdArrowDropDown } from "react-icons/md";
import SummerBanner from "components/SummerBanner";
import { DateObject } from "react-multi-date-picker";
import toastErrorAndReport from "lib/toastErrorAndReport";
import { sendAppierEvent } from "lib/appier";
import { getCityRegion } from "lib/delivery";
import NewYearBanner from "components/NewYearBanner";
import { getNewStartDays } from "lib/common";

const HOLIDAY_LIST = [
  {
    start_date: "2023/09/29",
    end_date: "2023/10/01",
    name: "#中秋連假",
    image: "/images/groupB/midAutumn.jpg",
  },
  {
    start_date: "2023/10/07",
    end_date: "2023/10/10",
    name: "#雙十連假",
    image: "/images/groupB/nationalDay.jpg",
  },
  {
    start_date: "2023/12/23",
    end_date: "2023/12/25",
    name: "#聖誕節",
    image: "/images/groupB/Christmas.jpg",
  },
  {
    start_date: "2023/12/30",
    end_date: "2024/01/01",
    name: "#跨年連假",
    image: "/images/groupB/newYear.jpg",
  },
  // {
  //   start_date: "2024/02/08",
  //   end_date: "2024/02/14",
  //   name: "#春節假期",
  //   image: "/images/groupB/ChineseNewYear.jpg",
  // },
  // {
  //   start_date: "2024/02/13",
  //   end_date: "2024/02/14",
  //   name: "#情人節",
  //   image: "/images/groupB/ValentinesDay.jpg",
  // },
  {
    start_date: "2024/02/27",
    end_date: "2024/02/28",
    name: "#228紀念日",
    image: "/images/groupB/228MemorialDay.jpg",
  },
  {
    start_date: "2024/04/03",
    end_date: "2024/04/07",
    name: "#清明連假",
    image: "/images/groupB/QingmingFestival.jpg",
  },
  {
    start_date: "2024/05/10",
    end_date: "2024/05/12",
    name: "#母親節",
    image: "/images/groupB/mothersDay.png",
  },
  {
    start_date: "2024/06/08",
    end_date: "2024/06/10",
    name: "#端午連假",
    image: "/images/groupB/端午節.jpg",
  },
  {
    start_date: "2024/06/22",
    end_date: "2024/06/23",
    name: "#夏至海邊行",
    image: "/images/groupB/夏至海邊行.jpg",
  },
  {
    start_date: "2024/07/06",
    end_date: "2024/07/07",
    name: "#暑期出遊",
    image: "/images/groupB/暑期出遊.jpg",
  },
  {
    start_date: "2024/08/08",
    end_date: "2024/08/11",
    name: "#父親節",
    image: "/images/groupB/父親節.jpg",
  },
  {
    start_date: "2024/09/14",
    end_date: "2024/09/17",
    name: "#中秋烤肉趣",
    image: "/images/groupB/中秋烤肉趣.jpg",
  },
  {
    start_date: "2024/10/10",
    end_date: "2024/10/13",
    name: "#雙十國慶",
    image: "/images/groupB/雙十國慶.jpg",
  },
  {
    start_date: "2024/12/25",
    end_date: "2024/12/27",
    name: "#慶祝聖誕",
    image: "/images/groupB/慶祝聖誕.png",
  },
  {
    start_date: "2024/12/29",
    end_date: "2025/01/01",
    name: "#跨年出遊",
    image: "/images/groupB/跨年出遊.jpg",
  },
  {
    start_date: "2025/01/10",
    end_date: "2025/01/13",
    name: "#寒假出遊",
    image: "/images/groupB/寒假出遊2025.jpg",
  },
  {
    start_date: "2025/02/14",
    end_date: "2025/02/16",
    name: "#情人節浪漫行",
    image: "/images/groupB/情人節浪漫行2025.jpg",
  },
  {
    start_date: "2025/02/28",
    end_date: "2025/03/02",
    name: "#和平紀念日",
    image: "/images/groupB/和平紀念日2025.jpg",
  },
  {
    start_date: "2025/04/03",
    end_date: "2025/04/06",
    name: "#清明兒童節",
    image: "/images/groupB/清明兒童節2025.jpg",
  },
  {
    start_date: "2025/05/01",
    end_date: "2025/05/02",
    name: "#勞動節",
    image: "/images/groupB/勞動節2025.jpg",
  },
  {
    start_date: "2025/05/09",
    end_date: "2025/05/11",
    name: "#母親節",
    image: "/images/groupB/母親節2025.jpg",
  },
  {
    start_date: "2025/05/17",
    end_date: "2025/05/20",
    name: "520浪漫行",
    image: "/images/groupB/520浪漫行2025.jpg",
  },
  {
    start_date: "2025/05/30",
    end_date: "2025/06/01",
    name: "端午節",
    image: "/images/groupB/端午節2025.jpg",
  },
  {
    start_date: "2025/06/20",
    end_date: "2025/06/22",
    name: "夏至海邊行",
    image: "/images/groupB/夏至海邊行2025.jpg",
  },
  {
    start_date: "2025/07/01",
    end_date: "2025/07/04",
    name: "暑假出遊",
    image: "/images/groupB/暑假出遊2025.jpg",
  },
  {
    start_date: "2025/08/08",
    end_date: "2025/08/10",
    name: "父親節",
    image: "/images/groupB/父親節2025.jpg",
  },
  {
    start_date: "2025/08/29",
    end_date: "2025/08/31",
    name: "七夕情人節",
    image: "/images/groupB/七夕情人節2025.jpg",
  },
  {
    start_date: "2025/09/06",
    end_date: "2025/09/09",
    name: "重陽節",
    image: "/images/groupB/重陽節2025.jpg",
  },
  {
    start_date: "2025/10/04",
    end_date: "2025/10/06",
    name: "中秋節",
    image: "/images/groupB/中秋節2025.jpg",
  },
  {
    start_date: "2025/10/10",
    end_date: "2025/10/12",
    name: "國慶日",
    image: "/images/groupB/國慶日2025.jpg",
  },
  {
    start_date: "2025/11/27",
    end_date: "2025/11/30",
    name: "感恩節",
    image: "/images/groupB/感恩節2025.jpg",
  },
  {
    start_date: "2025/12/19",
    end_date: "2025/12/21",
    name: "冬至",
    image: "/images/groupB/冬至2025.jpg",
  },
  {
    start_date: "2025/12/24",
    end_date: "2025/12/28",
    name: "聖誕節",
    image: "/images/groupB/聖誕節2025.jpg",
  },
  {
    start_date: "2025/12/31",
    end_date: "2026/01/01",
    name: "元旦",
    image: "/images/groupB/元旦2025.jpg",
  },
];

const ModeBtn = ({ mode, value, title, setMode }) => {
  return (
    <Flex
      flex="1"
      justify="center"
      align="center"
      bg={mode === value ? "#28292B" : "#F3F4F9"}
      borderRadius="6px"
      cursor="pointer"
      onClick={() => {
        setMode(value);
      }}
    >
      <Text
        fontSize="16px"
        lineHeight="24px"
        fontWeight="700"
        color={mode === value ? "white" : "#333333"}
      >
        {title}
      </Text>
    </Flex>
  );
};

const HomeHero = ({ ownerSettings, deliveryZones: deliveryZoneList }) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { locale } = router;
  const isTw = locale === "zh-TW";
  const {
    selectedDays,
    selectedCity,
    selectedZone,
    selectedStation,
    address,
    deliveryZones,
    tempSelectedCity,
    selectedZoneId,
    isSearchAirport,
    tempSelectedDays,
  } = appStore;
  const [addresslevel1List, setAddresslevel1List] = useState([]);
  const [defaultSelectedDays, setDefaultSelectedDays] = useState(selectedDays);

  const [holidayList, setHolidayList] = useState([]);
  const [searchText, setSearchText] = useState(selectedCity + selectedZone);

  const [addressInputZoneId, setAddressInputZoneId] = useState(
    searchText === "" ? "" : selectedZoneId
  );

  const [isVaild, setIsVaild] = useState(false);
  const [mode, setMode] = useState("station");

  useEffect(() => {
    if (selectedDays.length === 2) {
      const isBetweenNewYear =
        isBefore(new Date(selectedDays[0]), new Date("2025-02-03")) &&
        isAfter(new Date(selectedDays[0]), new Date("2025-01-23"));
      if (isBetweenNewYear) {
        appStore.selectedDays = getNewStartDays();
        appStore.tempSelectedDays = getNewStartDays();
        setDefaultSelectedDays(getNewStartDays());
      }
    }
  }, []);

  useEffect(() => {
    // 判斷現在時間拿取 HOLIDAY_LIST 裡的兩筆資料
    const now = new Date();
    const _holidayList = HOLIDAY_LIST.filter((item) => {
      const start = new Date(item.start_date);
      return isBefore(now, start);
    });

    // 取得下一個週五跟週日的日期
    const nextFriday = new Date();
    nextFriday.setDate(
      nextFriday.getDate() +
        ((5 + 7 - nextFriday.getDay()) % 7) +
        (nextFriday.getDay() == 5 ? 7 : 0)
    );
    const nextSunday = new Date(addDays(nextFriday, 2));

    const weekendData = {
      start_date:
        format(nextFriday, "yyyy/MM/dd") === "2024/02/09"
          ? "2024/02/16"
          : format(nextFriday, "yyyy/MM/dd"),
      start_time: "19:00",
      end_date:
        format(nextSunday, "yyyy/MM/dd") === "2024/02/11"
          ? "2024/02/18"
          : format(nextSunday, "yyyy/MM/dd"),
      end_time: "19:00",
      name: "#週末出遊吧",
      image: "/images/groupB/週末出遊吧.jpg",
    };
    setHolidayList([..._holidayList.slice(0, 2), weekendData]);
  }, []);

  useEffect(() => {
    appStore.configs = ownerSettings.map((config) => ({
      ...config,
      owner_id: config.location_id || "udrive",
    }));

    appStore.deliveryZones = deliveryZoneList;
    const { latestOrderTime, timeOpts, deliveryZonesObj } =
      getDatePickerSettingsByConfigs(ownerSettings, deliveryZoneList);
    appStore.timeOptsUnion = timeOpts;
    appStore.latestOrderTimeUnion = latestOrderTime;
    appStore.cityUnion = deliveryZonesObj;

    setAddresslevel1List(Object.keys(deliveryZonesObj));
    appStore.loading = false;
  }, [deliveryZoneList, ownerSettings]);

  const goRentOrStationPage = (data) => {
    let zone = {};

    if (
      (addressInputZoneId == "" || searchText == "") &&
      data.btn_clicked != "suggestion" &&
      data.page == "/rent"
    ) {
      setIsVaild(true);
      setTimeout(() => {
        setIsVaild(false);
      }, 3000);
      return;
    }
    const _zone = deliveryZones.find((item) => item.id == addressInputZoneId);
    if (mode === "rent" && _zone?.level_3 !== null && _zone?.level_3 !== "") {
      setIsVaild(true);
      setTimeout(() => {
        setIsVaild(false);
      }, 3000);
      return;
    }

    if (!addressInputZoneId) {
      if (isSearchAirport) {
        zone = deliveryZones.find((item) => item.id == "2");
        appStore.isAirport = 1;
      } else {
        zone = deliveryZones.find((item) => item.id == "56");
      }
    } else {
      zone = deliveryZones.find((item) => item.id == addressInputZoneId);
    }

    if (data?.start_date && data?.end_date) {
      const date = [
        new DateObject(data.start_date),
        new DateObject(data.end_date),
      ];
      if (date[0].format("YYYY/MM/DD") === date[1].format("YYYY/MM/DD")) {
        toastErrorAndReport(
          `${t("timeError")}`,
          "HomeTimeSearchBar/search/date"
        );
        return;
      }

      setDefaultSelectedDays(date);

      appStore.selectedDays = date;
      appStore.tempSelectedDays = date;
    } else {
      if (
        defaultSelectedDays[0].format("YYYY/MM/DD") ===
        defaultSelectedDays[1].format("YYYY/MM/DD")
      ) {
        toastErrorAndReport(
          `${t("timeError")}`,
          "HomeTimeSearchBar/search/date"
        );
        return;
      }
      appStore.selectedDays = defaultSelectedDays;
      appStore.tempSelectedDays = defaultSelectedDays;
    }
    if (data?.start_time && data?.end_time) {
      appStore.selectedStartClock = data.start_time;
      appStore.tempSelectedStartClock = data.start_time;
      appStore.selectedEndClock = data.end_time;
      appStore.tempSelectedEndClock = data.end_time;
    }

    appStore.selectedZoneId = zone.id;
    appStore.selectedCity = zone.level_1;
    appStore.selectedZone = zone.level_2;
    appStore.selectedStation = zone.level_3 || "chargeZone";
    appStore.selectedReturnCity = zone.level_1;
    appStore.selectedReturnZone = zone.level_2;
    appStore.selectedReturnStation = zone.level_3 || "chargeZone";
    appStore.selectedReturnZoneId = zone.id;
    appStore.tempSelectedZoneId = zone.id;
    appStore.tempSelectedCity = zone.level_1;
    appStore.tempSelectedZone = zone.level_2;
    appStore.tempSelectedStation = zone.level_3 || "chargeZone";
    appStore.tempSelectedReturnZoneId = zone.id;
    appStore.tempSelectedReturnCity = zone.level_1;
    appStore.tempSelectedReturnZone = zone.level_2;
    appStore.tempSelectedReturnStation = zone.level_3 || "chargeZone";

    const queryString = {
      ...router.query,
    };

    const pathname = data?.page ? data.page : "/station";
    const query = data?.btn_clicked
      ? { ...queryString, btn_clicked: "suggestion" }
      : queryString;

    // 追蹤
    if (data?.fbq) {
      fbq("track", "Search");
      ga.event({
        action: "search",
      });
      sendAppierEvent("searched", {
        searched_type: pathname === "/station" ? "同站租還" : "外送到府",
        searched_location: `${zone?.level_1}${zone?.level_2}`,
        searched_city: zone?.level_1,
        searched_zone: zone?.level_2,
        searched_city_region: getCityRegion(zone?.level_1),
        searched_station: zone?.level_3 || "",
      });
    }

    // 推薦行程的 Banner
    if (data.btn_clicked === "suggestion") {
      const { item } = data;
      sendAppierEvent("banner_clicked", {
        banner_id: `HomeHeroSuggestion_${item.name}_${item.start_date}_${item.end_date}`,
        banner_name: item.name,
        // FIXME: 不確定要不要處理 QueryString
        redirect_url: window.location.origin + pathname,
      });
    }

    router.push({
      pathname,
      query,
    });
  };

  // 判斷時間是否超過 2024/02/15
  const isAfter15 = isAfter(new Date(), new Date("2024/02/14 23:59:59"));
  const isAfterSummer = isAfter(new Date(), new Date("2024/09/15 15:59:59"));

  const onClickGoogleMapResult = (item) => {
    let _city = "";
    let _zone = "";
    const matchDeliveryZone = deliveryZones
      .filter((zone) => zone.level_3 === null || zone.level_3 === "")
      .find((zone) => {
        const isMatch = item.terms.find((term, index) => {
          if (index !== 0) {
            if (
              (term.value.includes(zone.level_1) ||
                zone.level_1.includes(term.value)) &&
              (item.terms[index - 1].value.includes(zone.level_2) ||
                zone.level_2.includes(item.terms[index - 1].value))
            ) {
              return true;
            } else {
              return false;
            }
          }
        });
        const matchIndex = item.terms.indexOf(isMatch);
        if (matchIndex !== -1) {
          _city = item.terms[matchIndex].value;
          _zone = item.terms[matchIndex - 1].value;

          return true;
        }
      });

    appStore.selectedCity = matchDeliveryZone.level_1;
    appStore.selectedZone = matchDeliveryZone.level_2;
    appStore.selectedStation =
      matchDeliveryZone.level_3 && matchDeliveryZone.level_3 !== null
        ? matchDeliveryZone.level_3
        : "";

    setAddressInputZoneId(matchDeliveryZone.id);
    appStore.selectedZoneId = matchDeliveryZone.id;
    appStore.tempSelectedCity = matchDeliveryZone.level_1;
    appStore.tempSelectedZone = matchDeliveryZone.level_2;
    appStore.tempSelectedStation =
      matchDeliveryZone.level_3 && matchDeliveryZone.level_3 !== null
        ? matchDeliveryZone.level_3
        : "";
    appStore.tempSelectedZoneId = matchDeliveryZone.id;
    appStore.address = item.description.split(_city + _zone)[1];
  };

  const onClickMappingResult = (item) => {
    appStore.selectedCity = item.level_1;
    appStore.selectedZone = item.level_2;
    appStore.selectedStation =
      item.level_3 && item.level_3 !== null ? item.level_3 : "";
    setAddressInputZoneId(item.id);
    appStore.selectedZoneId = item.id;
    appStore.tempSelectedCity = item.level_1;
    appStore.tempSelectedZone = item.level_2;
    appStore.tempSelectedStation =
      item.level_3 && item.level_3 !== null ? item.level_3 : "";
    appStore.address = item.address;
    appStore.tempSelectedZoneId = item.id;
  };

  return (
    <>
   
      <Box mb={{ base: isTw ? "0px" : "120px", md: "0px" }}>
        <BackgroundImageBox imageUrl="https://storage.googleapis.com/udrive-web/images/groupB/heroImage.jpg">
          <Box
            textAlign="start"
            pt={{ base: "24px", md: "72px" }}
            mx={{
              base: "20px",
              md: "5%",
              lg: "calc((100% - 912px) / 2)",
              xl: "calc((100% - 960px) / 2)",
            }}
          >
            <Text
              fontSize={{ base: "24px", md: "32px" }}
              fontWeight="700"
              lineHeight={{ base: "32px", md: "40px" }}
              color="white"
            >
              {t("homeTitle1")}
              {t("homeTitle2")}
            </Text>
            <Box
              mt={{ base: "8px", md: "16px" }}
              mb={{ base: "16px", md: "64px" }}
            >
              <Text
                as="span"
                fontSize={{ base: "16px", md: isTw ? "20px" : "18px" }}
                fontWeight="400"
                lineHeight={{ base: "24px", md: "28px" }}
                color="white"
              >
                {t("Index_subtitle_groupb_1", { defaultValue: "" })}
              </Text>
              <Text
                as="span"
                fontSize={{ base: "16px", md: isTw ? "20px" : "18px" }}
                fontWeight="400"
                lineHeight={{ base: "24px", md: "28px" }}
                color="#F5C825"
              >
                {t("Index_subtitle_groupb_2")}
              </Text>
              <Text
                as="span"
                fontSize={{ base: "16px", md: isTw ? "20px" : "18px" }}
                fontWeight="400"
                lineHeight={{ base: "24px", md: "28px" }}
                color="white"
              >
                {t("Index_subtitle_groupb_3")}
              </Text>
            </Box>
          </Box>

          {/* Search Bar */}
          <Box
            bg="white"
            mx={{ base: "16px", md: "24px" }}
            w={{ base: "90%", lg: "912px", xl: "960px" }}
            border="1px solid rgba(0, 0, 0, 0.10)"
            boxShadow="0px 6px 16px 3px rgba(0, 0, 0, 0.25)"
            borderRadius="8px"
            p={{ base: "16px", md: "24px" }}
            pt={{ base: "16px", md: "16px" }}
            pos="relative"
          >
            {/* 模式選擇 */}
            <Flex
              w="100%"
              h="48px"
              maxH="48px"
              justify="center"
              mb={{ base: "24px", md: "24px" }}
            >
              <Flex
                h="100%"
                w="100%"
                maxW="400px"
                bg="#F3F4F9"
                p="4px"
                borderRadius="8px"
              >
                <ModeBtn
                  mode={mode}
                  value="station"
                  title={t("HomeHero_station_btn")}
                  setMode={setMode}
                />
                <ModeBtn
                  mode={mode}
                  value="rent"
                  title={t("HomeHero_rent_btn")}
                  setMode={setMode}
                />
              </Flex>
            </Flex>
            {mode === "station" ? (
              <Box>
                <Text
                  textAlign="start"
                  fontSize="24px"
                  fontWeight="700"
                  lineHeight="32px"
                  color="#333333"
                >
                  {t("whenPickup")}
                </Text>
                <Flex
                  mt="16px"
                  display={{ base: "none", md: "flex" }}
                  align="center"
                >
                  <Checkbox
                    size="md"
                    isChecked={isSearchAirport}
                    onChange={(e) => {
                      appStore.isSearchAirport = e.target.checked;
                    }}
                  />
                  <Text
                    ml="8px"
                    fontSize={{ base: "14px", md: "16px" }}
                    fontWeight="400"
                    lineHeight={{ base: "20px", md: "24px" }}
                    color="#333333"
                  >
                    {t("HomeHero_airport_checkbox")}
                  </Text>
                </Flex>
                <Flex flexDir={{ base: "column-reverse", md: "column" }}>
                  <Flex
                    mt="16px"
                    flexDir={{ base: "column", md: "row" }}
                    align={{ base: "stretch", md: "center" }}
                  >
                    <Flex
                      h="56px"
                      maxH="56px"
                      flex="1"
                      align="center"
                      border="1px solid rgba(0, 0, 0, 0.40)"
                      borderRadius="8px"
                      py="8px"
                      px="16px"
                    >
                      <GCPImage
                        src="/images/groupB/Icon_calendar.png"
                        w="24px"
                        h="24px"
                      />
                      <HomeHeroTimeSelectBox
                        values={defaultSelectedDays}
                        setValues={setDefaultSelectedDays}
                      />
                      <MdArrowDropDown fontSize="24px" />
                    </Flex>

                    <Flex mt="16px" display={{ base: "flex", md: "none" }}>
                      <Checkbox />
                      <Text
                        ml="8px"
                        fontSize={{ base: "14px", md: "16px" }}
                        fontWeight="400"
                        lineHeight={{ base: "20px", md: "24px" }}
                        color="#333333"
                      >
                        {t("HomeHero_airport_checkbox")}
                      </Text>
                    </Flex>
                    <Box
                      flex={{ base: 1, md: 0 }}
                      ml={{ base: "0px", md: "16px" }}
                      mt={{ base: "16px", md: "0px" }}
                    >
                      <Button
                        w="100%"
                        px="20px"
                        h="56px"
                        color="white"
                        bg="#3374F6"
                        _active={{ bg: "#3374F6" }}
                        _focus={{ boxShadow: "none", bg: "#3374F6" }}
                        _hover={{ bg: "#3374F6" }}
                        onClick={() => {
                          goRentOrStationPage({
                            fbq: true,
                          });
                        }}
                      >
                        {t("search")}
                      </Button>
                    </Box>
                  </Flex>
                </Flex>
              </Box>
            ) : (
              <Box>
                <Text
                  textAlign="start"
                  fontSize="24px"
                  fontWeight="700"
                  lineHeight="32px"
                  color="#333333"
                >
                  {t("wherePickup")}
                </Text>
                <Flex
                  mt="16px"
                  flexDir={{ base: "column", md: "row" }}
                  align={{ base: "stretch", md: "center" }}
                >
                  <Tooltip
                    hasArrow
                    isOpen={isVaild}
                    label={t("Home_test_tooltip")}
                  >
                    <Flex flex="1">
                      <HomeHeroAddressInput
                        placeholder={t("google_map_input_placeholder")}
                        searchText={searchText}
                        setSearchText={setSearchText}
                        onClickMappingResult={onClickMappingResult}
                        onClickGoogleMapResult={onClickGoogleMapResult}
                        setAddressInputZoneId={setAddressInputZoneId}
                      />
                    </Flex>
                  </Tooltip>

                  <Flex
                    ml={{ base: "0px", md: "16px" }}
                    mt={{ base: "16px", md: "0px" }}
                    h="56px"
                    maxH="56px"
                    flex="1"
                    align="center"
                    border="1px solid rgba(0, 0, 0, 0.40)"
                    borderRadius="8px"
                    py="8px"
                    px="16px"
                  >
                    <GCPImage
                      src="/images/groupB/Icon_calendar.png"
                      w="24px"
                      h="24px"
                    />
                    <HomeHeroTimeSelectBox
                      values={defaultSelectedDays}
                      setValues={setDefaultSelectedDays}
                    />
                    <MdArrowDropDown fontSize="24px" />
                  </Flex>
                  <Box
                    flex={{ base: 1, md: 0 }}
                    ml={{ base: "0px", md: "16px" }}
                    mt={{ base: "16px", md: "0px" }}
                  >
                    <Button
                      w="100%"
                      px="20px"
                      h="56px"
                      maxH="56px"
                      color="white"
                      bg="#3374F6"
                      _active={{ bg: "#3374F6" }}
                      _focus={{ boxShadow: "none", bg: "#3374F6" }}
                      _hover={{ bg: "#3374F6" }}
                      onClick={() => {
                        goRentOrStationPage({
                          fbq: true,
                          page: "/rent",
                        });
                      }}
                    >
                      {t("search")}
                    </Button>
                  </Box>
                </Flex>
              </Box>
            )}
          </Box>
        </BackgroundImageBox>
        {/* {!isAfter15 && (
      <Flex
        mx={{
          base: "0px",
          md: "5%",
          lg: "calc((100% - 912px) / 2)",
          xl: "calc((100% - 960px) / 2)",
        }}
        w={{ base: "100%", md: "90%", lg: "912px", xl: "960px" }}
        mt={{ base: "-72px", md: "100px" }}
        mb={{ base: "24px", md: locale == "zh-TW" ? "" : "0px" }}
        cursor="pointer"
        onClick={() => {
          goRentPage({ page: "/newYear" });
        }}
      >
        <NewYearBanner />
      </Flex>
    )} */}
        {!isAfterSummer && (
          <Flex
            mx={{
              base: "0px",
              md: "5%",
              lg: "calc((100% - 912px) / 2)",
              xl: "calc((100% - 960px) / 2)",
            }}
            w={{ base: "100%", md: "90%", lg: "912px", xl: "960px" }}
            mt={{ base: "0px", md: mode === "station" ? "220px" : "180px" }}
            mb={{ base: "24px", md: locale == "zh-TW" ? "" : "0px" }}
            cursor="pointer"
            onClick={() => {
              router.push({
                pathname: "/summer",
                query: router.query,
              });
            }}
          >
            <SummerBanner />
          </Flex>
        )}

        {locale === "zh-TW" && (
          <Box
            mt={
              isAfterSummer
                ? { base: "24px", md: mode === "station" ? "229px" : "189px" }
                : "0px"
            }
            mb={{ base: "24px", md: "0px" }}
            mx={{
              base: "20px",
              md: "5%",
              lg: "calc((100% - 912px) / 2)",
              xl: "calc((100% - 960px) / 2)",
            }}
          >
            <Grid
              templateColumns="repeat(3, 1fr)"
              gap={{ base: "8px", md: "16px" }}
            >
              {holidayList.map((item) => (
                <GridItem
                  key={item.name}
                  w="100%"
                  maxH={{ base: "80px", md: "120px" }}
                  borderRadius="8px"
                  overflow="hidden"
                  bg="#313641"
                  cursor="pointer"
                  onClick={() => {
                    goRentOrStationPage({
                      start_date: item.start_date,
                      end_date: item.end_date,
                      start_time: item.start_time,
                      end_time: item.end_time,
                      btn_clicked: "suggestion",
                      item,
                    });
                  }}
                >
                  <Flex pos="relative">
                    <Box
                      flex={{ base: "0", md: "1" }}
                      display={{ base: "none", md: "block" }}
                      pos="relative"
                    >
                      <Box
                        borderRadius="0px 0px 0px 4px"
                        right="0"
                        top="0"
                        pos="absolute"
                        w="28px"
                        h="16px"
                        bg="linear-gradient(108deg, #D42828 0%, #F18787 100%)"
                        align="center"
                        justify="center"
                      >
                        <Text
                          fontSize="12px"
                          fontWeight="700"
                          lineHeight="16px"
                          color="white"
                        >
                          {differenceInDays(
                            new Date(item.end_date),
                            new Date(item.start_date)
                          ) + 1}
                          {t("days")}
                        </Text>
                      </Box>
                    </Box>
                    <Box
                      maxW={{ base: "100%", md: "170px" }}
                      w="100%"
                      h={{ base: "80px", md: "120px" }}
                      bgRepeat="no-repeat"
                      bgPosition="center"
                      bgSize="cover"
                      bgImage={`https://storage.googleapis.com/udrive-web${item.image}`}
                    >
                      <Box
                        pos="absolute"
                        left={{ base: "8px", md: "16px" }}
                        bottom={{ base: "8px", md: "16px" }}
                        zIndex="9"
                      >
                        <Text
                          fontSize="12px"
                          fontWeight="700"
                          lineHeight="16px"
                          color="white"
                        >
                          {item.name}
                        </Text>
                        <Text
                          fontSize="12px"
                          fontWeight="400"
                          lineHeight="16px"
                          color="rgba(255, 255, 255, 0.75)"
                        >
                          {format(new Date(item.start_date), "MM/dd")} -{" "}
                          {format(new Date(item.end_date), "MM/dd")}
                        </Text>
                      </Box>
                      <Box display={{ base: "block", md: "none" }}>
                        <Box
                          bottom="0"
                          h="46px"
                          w="100%"
                          pos="absolute"
                          bg="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)"
                        ></Box>
                        <Box
                          borderRadius="0px 0px 0px 4px"
                          right="0"
                          top="0"
                          pos="absolute"
                          w="28px"
                          h="16px"
                          bg="linear-gradient(108deg, #D42828 0%, #F18787 100%)"
                          align="center"
                          justify="center"
                        >
                          <Text
                            fontSize="12px"
                            fontWeight="700"
                            lineHeight="16px"
                            color="white"
                          >
                            {differenceInDays(
                              new Date(item.end_date),
                              new Date(item.start_date)
                            ) + 1}
                            {t("days")}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </Flex>
                </GridItem>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
    </>
  );
};

export default HomeHero;
